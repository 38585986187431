<template>
  <div ref="chat-section">
    <BaseLoading style="min-height: 400px" v-if="loading_mgs"></BaseLoading>
    <div v-else>
      <div class="" v-if="order && Object.keys(order).length > 0">
        <div class="card-header header-elements-inline">
          <h6 class="card-title font-weight-bold text-uppercase px-0 pt-1">
            Order ID:
            <router-link :to="{ name: 'Order', params: { id: order.id } }"
              >#{{ order.id || "" }}</router-link
            >
          </h6>
          <div class="header-elements">
            <div class="list-icons">
              <!-- <a class="list-icons-item" data-action="reload"></a> -->
            </div>
          </div>
        </div>

        <div class="card-body px-1">
          <ul class="media-list media-chat media-chat-scrollable mb-3">
            <span v-if="orderMessages && orderMessages.length > 0">
              <span
                v-for="(message, index) in orderMessages"
                :key="index + 'msgs'"
              >
                <li v-if="!parseInt(message.is_sender)" class="media">
                  <div class="mr-3">
                    <a href="/img/image.58f01911.png">
                      <img
                        src="/img/image.58f01911.png"
                        class="rounded-circle"
                        alt=""
                        width="40"
                        height="40"
                      />
                    </a>
                  </div>

                  <div class="media-body mb-2">
                    <div class="media-chat-item">
                      <p class="font-weight-bold mb-0 text-primary">
                        {{ message.user_type || "" }}
                      </p>
                      <pre v-html="message.message || ''"></pre>
                      <div class="font-size-sm text-muted mt-2">
                        {{ message.create_stamp || "" }}
                      </div>
                    </div>
                  </div>
                </li>

                <li v-else class="media media-chat-item-reverse">
                  <div class="media-body">
                    <div class="media-chat-item">
                      <p class="font-weight-bold mb-0 text-light">
                        {{ message.user_type || "" }}
                      </p>
                      <pre
                        class="text-light"
                        v-html="message.message || ''"
                      ></pre>
                    </div>
                    <div class="font-size-sm text-muted mt-2">
                      {{ message.create_stamp || "" }}
                    </div>
                  </div>

                  <div class="ml-3">
                    <a href="/img/image.58f01911.png">
                      <img
                        src="/img/image.58f01911.png"
                        class="rounded-circle"
                        alt=""
                        width="40"
                        height="40"
                      />
                    </a>
                  </div>
                </li>
              </span>
            </span>
            <!-- notes -->
            <v-alert dense outlined type="primary ">
              <small>
                Simply Type in your message and click on send. The message will
                be received by the assigned writer and support team.
              </small>
            </v-alert>
            <v-alert dense outlined type="error">
              <small
                >Your personal information (Phone Number, Email Address) is
                always kept private and we won't share it–just don't include it
                in your order details.</small
              ></v-alert
            >
            <!-- /notes -->
          </ul>

          <form>
            <textarea
              name="enter-message"
              class="form-control mb-3"
              rows="3"
              cols="1"
              v-model="messageForm.message"
              required
              placeholder="Enter your message..."
            ></textarea>

            <label class="text-danger"> Select a reciepient </label>

            <div class="d-flex mt-0 content-center" style="align-items: center">
              <div class="form-check" v-if="order.client_id">
                <input
                  v-model="messageForm.receiver_id"
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  :value="order.client_id"
                  @click="receiver = 'client'"
                />
                <label class="form-check-label" for="exampleRadios2">
                  Client
                </label>
              </div>
              <div class="mx-2"></div>
              <div
                class="form-check"
                v-if="order.writer_id && userType != userTypes.writer"
              >
                <input
                  v-model="messageForm.receiver_id"
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  :value="order.writer_id"
                  @click="receiver = 'writer'"
                />
                <label class="form-check-label" for="exampleRadios2">
                  Writer
                </label>
              </div>

              <div class="d-flex" v-if="userType == userTypes.writer">
                <div
                  class="form-check"
                  v-if="order.writer && order.writer.editor_id"
                >
                  <input
                    v-model="messageForm.receiver_id"
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    :value="order.writer.editor_id"
                    @click="receiver = 'editor'"
                  />
                  <label class="form-check-label" for="exampleRadios2">
                    Editor
                  </label>
                </div>

                <div class="form-check ml-2">
                  <input
                    v-model="messageForm.receiver_id"
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    :value="-1"
                    @click="receiver = 'support'"
                  />
                  <label class="form-check-label" for="exampleRadios2">
                    Support
                  </label>
                </div>
              </div>

              <div
                class="form-check ml-2 mr-0"
                v-if="
                  userType == userTypes.admin &&
                  order.writer &&
                  order.writer.editor_id
                "
              >
                <input
                  v-model="messageForm.receiver_id"
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  :value="order.writer.editor_id"
                  @click="receiver = 'editor'"
                />
                <label class="form-check-label" for="exampleRadios2">
                  Editor
                </label>
              </div>

              <button
                type="button"
                class="btn bg-primary-400 btn-labeled btn-labeled-right ml-auto"
                @click.stop="confirmDialog = true"
                :disabled="messageForm.busy"
              >
                <b><i class="icon-paperplane"></i></b> Send
              </button>
            </div>

            <v-dialog v-model="confirmDialog" max-width="290">
              <v-card>
                <v-card-title class="text-base"> Confirm </v-card-title>

                <v-card-text class="text-left">
                  <span v-if="!!receiver">
                    Are you sure you want to send this message to
                    <strong>{{ receiver }}</strong>
                  </span>
                  <span v-else> Kindly select a reciepient </span>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="error darken-1"
                    text
                    @click="confirmDialog = false"
                  >
                    Close
                  </v-btn>

                  <v-btn
                    v-if="!!receiver"
                    color="primary darken-1"
                    text
                    @click="sendMessage"
                  >
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </form>
        </div>
      </div>

      <div v-else class="card-body">
        <h5 class="py-4 mx-2">Select a conversation to start chatting</h5>
      </div>
    </div>
    <BaseOverlay v-if="sending"></BaseOverlay>
  </div>
</template>


<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
export default {
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chatHeight: 0,
      loading_mgs: false,
      sending: false,
      receiver: "",
      confirmDialog: false,
    };
  },
  computed: {
    ...mapState("messages", ["orderMessages", "messageForm"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
    // ...mapState("order", ["order"])
  },
  async mounted() {
    this.loading_mgs = true;
    await this.getMessages()
      .then((res) => {
        console.log("Messages res ", res);
      })
      .catch((err) => {
        console.log("Messages err ", err);
      })
      .finally(() => {
        this.loading_mgs = false;
      });
  },

  methods: {
    ...mapActions("messages", ["_getMessages", "_sendMessage"]),
    ...mapMutations("messages", ["setMessages"]),

    scrollToBottom() {
      var element = this.$refs["inout-section"];
      element.scrollTop = element.scrollHeight;
      console.log("this.chatHeight", element.scrollHeight);
    },

    async getMessages() {
      try {
        console.log("this.$route.params.id", this.$route.params.id);
        await this._getMessages(this.$route.params.id || this.order.id);
      } catch (error) {
        this.loading_mgs = false;
      }
    },
    sendMessage() {
      this.sending = true;
      this.confirmDialog = false;
      this._sendMessage({
        messageForm: this.messageForm,
        order_id: this.order.id,
      })
        .then((res) => {
          console.log(res);
          this.messageForm.reset();
          // success
        })
        .catch((err) => {
          console.log(err);
          // handle error
        })
        .finally(() => {
          this.sending = false;
          this.scrollToBottom();
        });
    },
  },
  beforeDestroy() {
    this.setMessages([]);
  },
};
</script>


<style lang="scss" scoped>
@media screen and (min-width: 800px) {
  .is-placeholder {
    min-height: 140px;
  }
  .is-chat-section {
    min-height: 7 8vh !important;
  }

  .stick-message-form {
    position: sticky;
    bottom: 5px;
  }

  .fix-message-form {
    margin-top: 20px;
    position: absolute;
    bottom: -10px;
    left: 20px;
    right: 34px;
  }
}
.is-chat-section {
  max-height: 78vh;
  min-height: 40vh;
  overflow-y: scroll;

  .media {
    width: 80%;

    &.is-out {
      .media-body {
        background: #dcf8c6;
      }
    }
    .media-body {
      box-shadow: 0 2px 5px #ccc;
    }
  }
}

@media screen and (min-width: 800px) {
  .is-chat-section {
    min-height: 85vh !important;
  }
  .stick-message-form {
    position: sticky;
    bottom: 5px;
    // position: absolute;
    // bottom: -10px;
    margin-bottom: 20px;
    // left: 10px;
    // right: 30px;
  }
}
pre {
  padding: 0 !important;
  border: none;
}
</style>